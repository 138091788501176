import { useRef } from 'react';
import { FormSpy, useFormState } from 'react-final-form';

const FormError = () => {
  const hasSubmitted = useRef(false);
  const { errors } = useFormState();

  return (
    <FormSpy
      onChange={({ modifiedSinceLastSubmit, submitFailed }) => {
        if (submitFailed && !hasSubmitted.current && Object.keys(errors ?? {})?.length > 0) {
          const firstObject = Object.keys(errors ?? {})?.[0];

          const firstErrorElement = document.getElementsByName(firstObject)?.[0];

          const yOffset = -220;
          const y = firstErrorElement?.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ behavior: 'smooth', top: y });
          hasSubmitted.current = true;
        }

        if (modifiedSinceLastSubmit) {
          hasSubmitted.current = false;
        }
      }}
      subscription={{
        modifiedSinceLastSubmit: true,
        submitFailed: true,
      }}
    />
  );
};

export default FormError;
