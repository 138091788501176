import { FormattedMessage } from 'react-intl';
import { formatISODate } from 'utils/dateUtil';
import { Message } from 'yup/lib/types';

/*
 * Central place for all Form validation messages
 * Use these as the 'message' parameter of your yup validation
 *
 * examples:
 * .min(giftCard.code.length.min, minLengthMessage)
 * .required(requiredMessage),
 */

export const minLengthMessage: Message<{ min: number }> = (obj) => (
  <FormattedMessage id="form_validation_min" values={{ min: obj.min.toString() }} />
);

export const maxLengthMessage: Message<{ max: number }> = (obj) => (
  <FormattedMessage id="form_validation_max" values={{ max: obj.max.toString() }} />
);

export const minValueMessage: Message<{ min: number }> = (obj) => (
  <FormattedMessage id="gift_card_form_validation_min" values={{ min: obj.min.toString() }} />
);

export const maxValueMessage: Message<{ max: number }> = (obj) => (
  <FormattedMessage id="gift_card_form_validation_max" values={{ min: obj.max.toString() }} />
);

export const numberMessage = () => <FormattedMessage id="form_validation_number" />;

export const requiredMessage = () => <FormattedMessage id="form_validation_required" />;

export const emailMessage = () => <FormattedMessage id="form_validation_email" />;

export const noDigitMessage = () => <FormattedMessage id="form_validation_no_digit" />;

export const noCapitalMessage = () => <FormattedMessage id="form_validation_no_capital" />;

export const containDigitMessage = () => <FormattedMessage id="form_validation_contain_digit" />;

export const phoneMessage = () => <FormattedMessage id="form_validation_phone" />;

export const textMessage = () => <FormattedMessage id="form_validation_text" />;

export const vatMessage = () => <FormattedMessage id="form_validation_vatNumber_not_valid" />;

export const vatIsocodeMessage = () => <FormattedMessage id="form_validation_vatNumber_isocode_not_valid" />;

export const vatInvalidNumberMessage = () => <FormattedMessage id="form_validation_vies_not_valid" />;

export const postalCodeNumberMessage = () => <FormattedMessage id="form_validation_postalcode_town" />;

export const postalCodeNumberMessageLU = () => <FormattedMessage id="form_validation_postalcode_town_LU" />;

export const postalCodeNumberMessageFR = () => <FormattedMessage id="form_validation_postalcode_town_FR" />;

export const postalCodeNumberMessageDE = () => <FormattedMessage id="form_validation_postalcode_town_DE" />;

export const postalCodeNumberMessageNL = () => <FormattedMessage id="form_validation_postalcode_town_NL" />;

export const companyNameMessage = () => <FormattedMessage id="form_validation_companyName" />;

export const fileSizeMessage = (maxFileSizeInBytes: number) => {
  const maxFileSizeInMB = Math.floor(maxFileSizeInBytes / 1024);
  return <FormattedMessage id="form_validation_fileSize" values={{ maxFileSize: maxFileSizeInMB }} />;
};

export const fileTypeMessage = (acceptedFileTypes?: string[]) => (
  <FormattedMessage id="form_validation_fileType" values={{ acceptedFileTypes: acceptedFileTypes?.join(', ') }} />
);

export const minimumDateMessage: Message<{ min: string | Date }> = (obj) => {
  const date = new Date(obj.min);
  return <FormattedMessage id="form_validation_date_minimum" values={{ minDate: formatISODate(date) }} />;
};

export const maximumDateMessage: Message<{ max: string | Date }> = (obj) => {
  const date = new Date(obj.max);
  return <FormattedMessage id="form_validation_date_maximum" values={{ maxDate: formatISODate(date) }} />;
};
