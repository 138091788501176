import { FileData } from 'constants/file';
import { setIn } from 'final-form';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { log } from './loggerUtil';

type ValidationSchema = yup.ObjectSchema<ObjectShape> | (() => yup.ObjectSchema<ObjectShape>);
type ValidationError = {
  inner: {
    message: string;
    path: string;
  }[];
};

const validateFormValues = (initialSchema: ValidationSchema) => async (values: Record<string, string>) => {
  const schema = typeof initialSchema === 'function' ? initialSchema() : initialSchema;

  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err: unknown) {
    // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    const errors = (err as ValidationError).inner.reduce(
      (formError, innerError) => setIn(formError, innerError.path, innerError.message),
      {}
    );

    return errors;
  }
  return null;
};

export const validateForm = (schema: ValidationSchema) => validateFormValues(schema);

export const getValidationSchema = (schemaObject: ObjectShape) => yup.object().shape(schemaObject);

export const mapFilesToData = async (files: File[]) => {
  try {
    const mappedFiles = await Promise.all(files.map((file) => mapFileToData(file)));
    return mappedFiles;
  } catch (error) {
    log('formUtil', 'Something went wrong when mapping files to data:', error);
  }
  return null;
};

const mapFileToData = (file: File): Promise<FileData> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const base64_string = String(fileReader.result).split(',')[1];
      resolve({ fileName: file.name, mime: file.type, size: file.size, url: base64_string });
    };
    fileReader.onerror = () => reject(`Something went wrong mapping file to data for file: ${file.name}`);
    fileReader.readAsDataURL(file);
  });
