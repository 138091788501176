const FormValidationSettings = {
  dateFormat: 'dd/MM/yyyy',
  validation: {
    addressTitle: {
      length: { max: 30, min: 2 },
    },
    articleNumber: {
      length: { max: 20, min: 6 },
    },
    checkoutRemarks: {
      length: { max: 255, min: 0 },
    },
    companyName: {
      length: { max: 100, min: 1 },
      regex: /\b[^\W]+\b/, // First char not allowed
    },
    companyPoNumber: { length: { max: 25, min: 0 } },
    contractKey: { length: { max: 14, min: 14 } },
    deliveryVoucher: {
      length: { max: 14, min: 14 },
    },
    email: {
      length: { max: 100 },
      regex: '^\\s*?(.+)@(.+?)\\s*$',
    },
    firstName: {
      length: { max: 100, min: 1 },
      regex: /\b[^\d\W]+\b/, // to prevent digits in the value
    },
    giftCard: {
      activationCode: {
        length: { max: 14, min: 14 },
      },
      message: {
        length: { max: 23 },
      },
      number: {
        length: { max: 19, min: 16 },
      },
      pin: {
        length: { max: 8, min: 6 },
      },
    },
    lastName: {
      length: { max: 100, min: 1 },
      regex: /\b[^\d\W]+\b/, // to prevent digits in the value
    },
    orderCode: {
      length: { max: 8, min: 8 },
    },
    password: {
      length: { max: 100, min: 6 },
      regex: { capitals: /^(?=.*[A-Z])/, numbers: /^(?=.*\d)/ },
    },
    phone: {
      regex: /^[0-9,+() ]{9,20}$/,
    },
    poBox: {
      length: { max: 6, min: 0 },
    },
    postalCode: {
      length: { min: 4 },
      regex: /^(?:L-)?[0-9a-zA-Z]{4,6}\s[-]\s[a-zA-ZzÀ-ÖØ-öø-ÿ-]{1,}/,
      regexBE: /^\d{4}\s-\s[a-zA-ZÀ-ÖØ-öø-ÿ-]{1,}/,
    },
    regex: {
      number: /^[0-9]+$/,
    },
    repairNumber: {
      length: { max: 14, min: 14 },
    },
    repairRemarks: {
      length: { max: 1000, min: 0 },
    },
    sealID: { length: { max: 8, min: 5 } },
    street: {
      length: { max: 40, min: 2 },
    },
    streetNumber: {
      length: { max: 6, min: 1 },
      regex: /.*[0-9].*/,
    },
    textarea: {
      length: { max: 150 },
    },
    ticketNumber: { length: { max: 14, min: 14 } },
    vatNumber: {
      regex:
        /^(BE)([0-9]{9,12}$)|(FR)([0-9A-Z]{2}[0-9]{9}$)|(LU)([0-9]{8}$)|(DE)([1-9]\d{8}$)|(NL){0,1}[0-9]{9}B[0-9O]{2}$/,
    },
  },
};

export default FormValidationSettings;
